<template>
  <div class="modal-body">
    <button type="button" @click="$emit('close')" class="modal-body__close">
      <CloseIcon />
    </button>
    <h1 class="modal-body__header">Отклонить заявку</h1>
    <div class="modal-body__form">
      <SelectComponent
        title="Выберите причину"
        labelName="title"
        v-model="$v.form.reason.value.$model"
        :error="$v.form.reason.value.$error"
        :options="reasons"
        multiple
      />
      <InputComponent
        title="Комментарий"
        :error="$v.form.message.value.$error"
        v-model.trim.lazy="$v.form.message.value.$model"
      />
      <div class="modal-body__controls">
        <button type="button" class="back-btn modal-body__controls--no-mobile" @click="$emit('close')">
          Отменить
        </button>
        <button
          :class="{
            'btn--loading': loading,
          }"
          class="btn"
          type="button"
          @click="submit"
        >
          Отправить
        </button>
        <button type="button" class="btn btn--outline modal-body__controls--mobile" @click="$emit('close')">
          Закрыть
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import CloseIcon from "@/components/svg/CloseIcon";
import SelectComponent from "@/components/inputs/SelectComponent";
import InputComponent from "@/components/inputs/InputComponent";
import { required } from "vuelidate/lib/validators";

export default {
  name: "DeclineUserModal",
  props: {
    options: Object,
  },
  beforeCreate() {
    this.$store.dispatch("GET_REASONS", {
      context: this,
      variables: {
        type_code: 2,
      },
    });
  },
  data() {
    return {
      loading: false,
      form: {
        reason: {
          message: null,
          value: null,
        },
        message: {
          message: null,
          value: null,
        },
      },
    };
  },
  validations: {
    form: {
      reason: {
        value: {
          required,
        },
      },
      message: {
        value: {
          required,
        },
      },
    },
  },
  computed: {
    reasons() {
      return this.$store.state.reasons;
    },
  },
  methods: {
    isValid() {
      this.$v.$touch();
      return !this.$v.$invalid;
    },
    submit() {
      if (this.isValid()) {
        this.options.callback(
          this.options.id,
          false,
          this.form.message.value,
          this.form.reason.value.map((v) => v.id)
        );
        this.$emit("close");
      }
    },
  },
  components: { InputComponent, SelectComponent, CloseIcon },
};
</script>
