<template>
  <div class="filter-select__list">
    <perfect-scrollbar class="filter-select__scroll">
      <div
        class="filter-select__item"
        :class="{
          'filter-select__item--single': !multiple,
          'filter-select__item--checked': isChecked(o),
        }"
        :key="i"
        @click="multiple ? false : $emit('select', o)"
        v-for="(o, i) in options"
        :tabindex="multiple ? -1 : 0"
      >
        <CheckComponent @change="$emit('select', o)" :checked="isChecked(o)" v-if="multiple">
          <span>{{ getName(o) }}</span>
        </CheckComponent>
        <template v-else>{{ getName(o) }}</template>
      </div>
      <div class="filter-select__item select__item--single" v-if="!options.length">Ничего не найдено</div>
    </perfect-scrollbar>
    <a class="filter-select__clean-list" @click.prevent="$emit('clean')" v-if="multiple"> Очистить </a>
  </div>
</template>

<script>
import { PerfectScrollbar } from "vue2-perfect-scrollbar";
import CheckComponent from "../CheckComponent";

export default {
  name: "FilterSelectListComponent",
  props: {
    labelName: { type: String, default: "name" },
    selected: { type: [Array, Number, Object] },
    options: { type: Array, required: true },
    multiple: Boolean,
  },
  components: {
    CheckComponent,
    PerfectScrollbar,
  },
  methods: {
    isChecked(option) {
      if (this.multiple && this.selected && this.selected instanceof Array) {
        return this.selected.map((s) => JSON.stringify(s)).includes(JSON.stringify(option));
      }
      if (!this.multiple) {
        return JSON.stringify(option) === JSON.stringify(this.selected);
      }
      return false;
    },
    getName(option) {
      if (this.labelName) {
        return option[this.labelName];
      }
      return option;
    },
  },
};
</script>

<style lang="stylus">
@import "~vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css"
.filter-select__list {
  absolute bottom left
  width calc(100% + 2px)
  transform translateY(calc(100% - 1px))
  z-index 10
  padding 7px 12px
  background var(--main_white)
  border 1px solid var(--main_light_divider)
  border-top-color transparent
  border-radius 0 0 4px 4px
  box-sizing: border-box;
  margin -1px
  box-shadow: var(--select_input_shadow);
  overflow hidden
  max-height: 250px;
  display grid
  grid-template-rows 1fr auto
}

.filter-select__scroll {
  display flex
  flex-direction column
  border-top 1px solid var(--main_light_divider)
  padding 8px 0
  grid-gap 4px
}

.filter-select__clean-list {
  font-weight: normal;
  font-size: 0.8750em; // 14px
  line-height: 18px;
  text-align: left;
  letter-spacing: var(--letter_spacing_small);
  color: var(--main_default_dark);
  border-top 1px solid var(--input_focused_border)
  border-bottom none
  cursor pointer
  text-decoration none
  padding 16px 0 8px

  &:hover {
    color var(--main_dark)
    border-color var(--input_focused_border)
  }
}

.filter-select__item {
  width 100%
  text-align left
  cursor pointer
  transition .3s
  outline none
  text-decoration none
  font-weight: normal;
  font-size: 0.8750em; // 14px
  line-height: 18px;
  letter-spacing: var(--letter_spacing);
  color: var(--main_dark_gray);
  display flex
  align-items center
  flex-shrink 0
  padding: 4px 0;

  &--single {
    padding 8px 0
  }

  &--checked {
    font-weight 500
    color var(--main_dark)
  }
}
</style>
