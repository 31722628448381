<template>
  <div
    class="date-select"
    :class="{
      'date-select--filled': singleSelected,
      'date-select--input': search.length || focused,
      'date-select--drop': opened,
      'date-select--loading': loading,
      'date-select--drop-top': listTop,
    }"
    v-click-outside="blurList"
  >
    <input
      @focus="focus"
      @blur="blur"
      ref="input"
      class="date-select__field"
      v-model="search"
      :placeholder="title"
      v-show="!loading"
      readonly
    />
    <span class="date-select__empty" @click="focus" v-show="!loading">
      {{ formatDate(singleSelected) || emptyPlaceholder }}
    </span>
    <button type="button" class="date-select__clear" v-if="singleSelected" @click="clean" v-show="!loading">
      <CloseIcon />
    </button>
    <button type="button" class="date-select__action" tabindex="-1" @click="focus" v-show="!loading">
      <CalendarIcon />
    </button>
    <div class="date-select__drop" v-show="!loading">
      <DatePicker
        v-model="singleSelected"
        v-if="opened"
        locale="ru"
        @input="handlePickerInput"
        :first-day-of-week="2"
        title-position="left"
        trim-weeks
        :mode="mode"
        :is-range="range"
      />
    </div>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";
import CalendarIcon from "../svg/CalendarIcon";
import DatePicker from "v-calendar/lib/components/date-picker.umd";
import moment from "moment";
import CloseIcon from "../svg/CloseIcon.vue";

export default {
  name: "DateComponent",
  props: {
    title: {
      type: String,
      required: true,
    },
    mode: {
      type: String,
      default: "date",
    },
    emptyPlaceholder: {
      type: String,
      default: "Все",
    },
    range: Boolean,
    loading: Boolean,
    value: [Object, Date],
  },
  data() {
    return {
      singleSelected: null,
      search: "",
      opened: false,
      listTop: false,
      scrollTimeout: undefined,
      focused: false,
    };
  },
  watch: {
    value() {
      this.singleSelected = this.value;
    },
    opened() {
      if (this.opened) {
        this.$nextTick(() => {
          this.calculateListPosition();
        });
      }
    },
  },
  mounted() {
    const placeholder = this.$refs.input.getAttribute("placeholder");
    this.$refs.input.setAttribute("size", placeholder.length - 2 + "");

    window.addEventListener("scroll", () => {
      if (this.opened) {
        clearTimeout(this.scrollTimeout);
        this.scrollTimeout = setTimeout(() => {
          this.$nextTick(() => {
            this.calculateListPosition();
          });
        }, 200);
      }
    });
  },
  beforeDestroy() {
    window.removeEventListener("scroll", () => {});
  },
  methods: {
    handlePickerInput(event) {
      if (this.mode === "date") {
        let start;
        if (event.start) {
          start = moment(event.start).startOf("day").toDate().toString();
        }
        let end;
        if (event.end) {
          end = moment(event.end).endOf("day").toDate().toString();
        }
        this.$emit("input", {
          start: start,
          end: end,
        });
      } else {
        this.$emit("input", event);
      }
    },
    formatDate(date) {
      const format = "DD.MM.YYYY";
      if (date) {
        if (date instanceof Date) {
          return moment(date).format(format);
        }
        return moment(date.start).format(format) + " - " + moment(date.end).format(format);
      }
      return date;
    },
    calculateListPosition() {
      if (this.$refs.list) {
        const height = this.$refs.list.$el.clientHeight;
        const top = this.$refs.list.$el.getBoundingClientRect().top;
        if (top + height > window.innerHeight) {
          this.listTop = true;
          return;
        }
        this.listTop = false;
      }
    },
    clean() {
      this.singleSelected = null;
      this.$emit("input", null);
    },
    /**
     * Обработка события выбора
     */
    select() {},
    /**
     * Фокус на input
     */
    focus() {
      this.$refs.input.focus();
      this.focused = true;
      this.opened = true;
    },
    /**
     * Расфокус на input
     */
    blur() {
      this.focused = false;
    },
    /**
     * Убрать список
     */
    blurList() {
      this.opened = false;
    },
  },
  components: {
    CloseIcon,
    CalendarIcon,
    DatePicker,
  },
  directives: {
    ClickOutside,
  },
};
</script>

<style lang="stylus">
@import "~@/styles/parts/input"
.date-select {
  @extends .input
  transition background-color .3s
  height 40px
  background transparent
  border-color var(--main_light_divider);
  border-width 1px
  border-radius: var(--button_small_radius);
  min-width 252px;
  display flex
  align-items center
  padding-right 42px

  &--loading {
    background-color var(--main_light_divider);
    background: linear-gradient(90deg, var(--main_table_header) 0%, var(--main_accent-o12) 50%, var(--main_table_header) 100%);
    background-size: 400% 100%;
    animation: gradient 2s ease infinite;
  }

  &__drop {
    absolute bottom left
    max-width calc(100% + 2px)
    transform translateY(calc(100% - 1px))
    z-index 10
    box-shadow: var(--select_input_shadow);

    .vc-container {
      font-family var(--font_regular)
      border-radius 0 0 4px 4px
      left -1px
      border-color var(--main_light_divider)
      border-top-color transparent
      --font-normal: 300;
      --font-medium: 400;
      --font-semibold: 500;
      --font-bold: 600;
      --text-xs: 12px;
      --text-sm: 0.8750em;
      --text-base: 1em;
      --text-lg: 18px;
      --leading-snug: 1.375;
      --rounded: 4px;
      --rounded-lg: 4px;
      --rounded-full: 100%;
      --shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
      --shadow-lg: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
      --shadow-inner: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
      --slide-translate: 22px;
      --slide-duration: 0.3s;
      --slide-timing: ease;
      --day-content-transition-time: 0.3s ease-in;
      --weeknumber-offset: -34px;

      .vc-title {
        text-transform: capitalize;
      }

      &.vc-blue {
        --accent-100: var(--main_blue-l80);
        --accent-200: var(--main_blue-l60);
        --accent-300: var(--main_blue-l40);
        --accent-400: var(--main_blue-l20);
        --accent-500: var(--main_blue);
        --accent-600: var(--main_blue-d20);
        --accent-700: var(--main_blue-d40);
        --accent-800: var(--main_blue-d60);
        --accent-900: var(--main_blue-d80);
      }
    }
  }

  &--filled {
    background var(--main_white)
  }

  &__empty {
    font-weight: 500;
    font-size: 0.8750em;
    line-height: 22px;
    letter-spacing: var(--letter_spacing_small);
    color: var(--main_dark);
    height 100%
    padding 9px 0
    display flex
    align-items center
    min-width 95px
    width 100%
  }

  &__action {
    padding 10px 12px
  }

  &--drop {
    background var(--main_white)
    border-radius 4px 4px 0 0
    border-bottom-color transparent

    .date-select__clear {
      z-index 12
    }
  }

  &--drop-top {
    border-radius 0 0 4px 4px

    .date-select__drop {
      border-radius 4px 4px 0 0
      absolute top left
      bottom initial
      transform translateY(calc(-100% + 2px))
    }

    .date-select__clear {
      z-index 12
    }
  }

  &__clear {
    @extend .date-select__action
    transform translateX(-50%)
    z-index 1
  }

  &__field {
    padding 9px 12px
    font-size: 0.8750em;
    line-height: 20px;
    z-index 1
    color var(--main_dark)
    width auto
    height auto
    padding-right: 4px;
    display inline-flex
    flex-shrink 0
  }

  &__selected {
    display grid
    grid-gap 4px
    grid-template-columns auto auto
    +below(480px) {
      display none
    }

    &::placeholder {
      color var(--main_default_dark)
    }

    &--one {
      grid-template-columns auto
    }

    .icon {
      width 12px
      height 12px
      display flex
      align-items center
      justify-content center
    }

    &-item {
      cursor pointer
      background var(--input_focused_border)
      border-radius 4px
      padding 2px 4px
      font-size: 0.8750em;
      line-height: 22px;
      text-align: center;
      letter-spacing: var(--letter_spacing_small);
      color: var(--main_black);
      text-decoration none

      &:hover {
        color var(--main_black)
      }

      &:first-child {
        display grid
        grid-template-columns auto 12px
        align-items center
      }
    }
  }

  &:not(.input-default)
  &:not(.date-select--input) {
    border-color var(--main_light_divider) !important;

    .date-select__title {
      font-size: 1em;
      line-height: 20px;
      width: 100%;
      height 100%
      padding 20px
    }
  }
}
</style>
