<template>
  <main class="page">
    <h1 class="page__mobile-title">Контрагенты</h1>
    <ContractorsListActions @change="actionsChange" @reset="actionsReset" />
    <Table
      :columns="columns"
      :rows="users.data || []"
      listClass="request-list"
      :total="users.paginatorInfo ? users.paginatorInfo.total : 0"
      :page="page"
      pagination
      :initialSortBy="vgtFormattedOrdering"
      @paginate="changePage"
      @sort="changeSort"
    >
      <div slot="empty" class="td-container">
        <span class="table-span">
          <LoadingIndicator v-if="loading" />
          <template v-else>Данных нет</template>
        </span>
      </div>
      <template slot="table-row" slot-scope="{ row, column }">
        <div class="td-container" v-if="column.field === 'created_at'">
          <span class="date">
            <span class="date__main">{{ row.created_at | formatDate }}</span>
            <span class="date__sub">{{ row.created_at | formatTime }}</span>
          </span>
        </div>
        <div class="td-container" v-if="column.field === 'company'">
          <span class="table-span">
            {{ row | getCompany }}
          </span>
        </div>
        <div class="td-container" v-if="column.field === 'roles'">
          <span class="date__main">
            {{ row.role | getChildRoles }}
          </span>
        </div>
        <div class="td-container" v-if="column.field === 'fio'">
          <span class="table-span">
            {{ row | getFIO }}
          </span>
        </div>
        <div class="td-container" v-if="column.field === 'phone'">
          <span class="table-span">
            {{ row.phone }}
          </span>
        </div>
        <div class="td-container" v-if="column.field === 'email'">
          <a :href="`mailto:${row.email}`" class="table-link">
            {{ row.email }}
          </a>
        </div>
        <div class="td-container" v-if="column.field === 'status'">
          <span :class="getStatus(row)">
            {{ row.status.name }}
          </span>
        </div>
        <div class="td-container" v-if="column.field === 'actions'">
          <div class="table-actions">
            <template v-if="row.status.code === 5">
              <a href="#" class="table-action table-action--green" @click.prevent="verify(row.id, true)">
                <LoaderIcon v-if="submitLoading" class="loading-rotate" />
                <CheckSymbolIcon v-else />
              </a>
              <a href="#" class="table-action table-action--red" @click.prevent="verifyModal(row.id)">
                <LoaderIcon v-if="declineLoading" class="loading-rotate" />
                <CloseIcon v-else />
              </a>
            </template>
            <a href="#" class="table-action" @click.prevent="editModal(row)">
              <EditIcon />
            </a>
          </div>
        </div>
      </template>
      <div slot="table-m-item" slot-scope="{ row }" class="card">
        <div class="card__header">
          <span :class="getStatus(row)">{{ row.status.name }}</span>
          <div class="contractor-btn__list">
            <button class="contractor-btn contractor-btn--green" type="button" @click="verify(row.id, true)">
              <LoaderIcon v-if="submitLoading" class="loading-rotate" />
              <CheckSymbolIcon v-else />
            </button>
            <button class="contractor-btn contractor-btn--red" type="button" @click="verifyModal(row.id)">
              <LoaderIcon v-if="declineLoading" class="loading-rotate" />
              <CloseIcon v-else />
            </button>
            <button class="contractor-btn contractor-btn--gray" type="button" @click="editModal(row)">
              <EditIcon />
            </button>
          </div>
        </div>
        <div class="card__body">
          <div class="card__item">
            <span class="card__item-title">Дата</span>
            <span class="card__item-value">
              {{ row.created_at | formatDate }} {{ row.created_at | formatTime }}
            </span>
          </div>
          <div class="card__item">
            <span class="card__item-title">Организация</span>
            <span class="card__item-value">{{ row | getCompany }}</span>
          </div>
          <div class="card__item">
            <span class="card__item-title">Роль</span>
            <span class="card__item-value">{{ row.role | getChildRoles }}</span>
          </div>
          <div class="card__item">
            <span class="card__item-title">ФИО</span>
            <span class="card__item-value">{{ row | getFIO }}</span>
          </div>
          <div class="card__item">
            <span class="card__item-title">Телефон</span>
            <span class="card__item-value">{{ row.phone }}</span>
          </div>
          <div class="card__item">
            <span class="card__item-title">Email</span>
            <span class="card__item-value">
              <a :href="`mailto:${row.email}`" class="table-link">{{ row.email }}</a>
            </span>
          </div>
        </div>
      </div>
    </Table>
  </main>
</template>

<script>
import ContractorsListActions from "./components/actions";
import Table from "@/components/Table";
import EditIcon from "@/components/svg/EditIcon";
import moment from "moment";
import CheckSymbolIcon from "@/components/svg/CheckSymbolIcon.vue";
import CloseIcon from "@/components/svg/CloseIcon.vue";
import DeclineUserModal from "./components/declineModal.vue";
import EditUserModal from "./components/editModal.vue";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import LoaderIcon from "../../components/svg/LoaderIcon.vue";

export default {
  name: "ContractorsList",
  data() {
    return {
      loading: false,
      submitLoading: false,
      declineLoading: false,
      page: 1,
      ordering: [
        {
          orderColumn: "created_at",
          orderBy: "DESC",
        },
      ],
      roles: null,
      start_date: null,
      end_date: null,
      status: null,
      status_tab: false,
    };
  },
  computed: {
    vgtFormattedOrdering() {
      return this.ordering.map((o) => ({
        field: o.orderColumn,
        type: o.orderBy.toLowerCase(),
      }));
    },
    users() {
      return this.$store.state.contractors;
    },
    me() {
      return this.$store.state.me;
    },
    columns() {
      return [
        {
          label: "Дата",
          field: "created_at",
        },
        {
          label: "Организация",
          field: "company",
        },
        {
          label: "Роль",
          field: "roles",
        },
        {
          label: "ФИО",
          field: "fio",
        },
        {
          label: "Телефон",
          field: "phone",
        },
        {
          label: "E-mail",
          field: "email",
        },
        {
          label: "Статус",
          field: "status",
        },
        {
          sortable: false,
          field: "actions",
        },
      ];
    },
  },
  created() {
    let storageVariables = localStorage.getItem("users_list." + this.me.id);
    if (storageVariables) {
      storageVariables = JSON.parse(storageVariables);
      Object.keys(storageVariables).forEach((key) => {
        if (Object.prototype.hasOwnProperty.call(this, key)) {
          this[key] = storageVariables[key];
        }
      });
    }
    this.getUsers();
  },
  methods: {
    editModal(user) {
      this.$store.state._modals.push({
        component: EditUserModal,
        options: {
          user: user,
          callback: this.getUsers,
        },
      });
    },
    verifyModal(id) {
      this.$store.state._modals.push({
        component: DeclineUserModal,
        options: {
          id: id,
          callback: this.verify,
        },
      });
    },
    verify(id, accept, message, reason) {
      if (accept && !confirm("Вы уверены?")) {
        return;
      }
      this.submitLoading = accept;
      this.declineLoading = !accept;
      this.$store
        .dispatch("VERIFY_CONTRACTORS", {
          context: this,
          variables: this.clean({
            user_id: id,
            message: message,
            reason_ids: reason,
            is_accepted: accept,
          }),
        })
        .then(() => {
          this.$notify({
            title: "Успешно",
            text: accept ? "Пользователь верифицирован" : "Пользователь заблокирован",
            type: "success",
          });
          this.getUsers();
        });
    },
    actionsChange(event) {
      this.page = 1;
      switch (event.name) {
        case "roles":
          this.changeField(event);
          break;
        case "status": {
          this.status_tab = event.status_tab;
          this.changeField(event);
          break;
        }
        case "date":
          if (event.value) {
            const format = "YYYY-MM-DD HH:mm:ss";
            this.start_date = moment(event.value.start).format(format);
            this.end_date = moment(event.value.end).format(format);
          } else {
            this.start_date = null;
            this.end_date = null;
          }
      }
      this.getUsers();
    },
    changeField(event) {
      if (event.value.length) {
        this[event.name] = event.value.map((p) => p.id);
      } else {
        this[event.name] = null;
      }
    },
    getStatus(row) {
      let string = "status";
      switch (row.status.code) {
        case 2:
        case 6:
          string += " status--red";
          break;
        case 5:
          string += " status--orange";
          break;
        case 1:
          string += " status--green";
          break;
      }
      return string;
    },
    getUsers() {
      if (!this.loading) {
        this.loading = true;
        this.$store.state.contractors = {};
        let variables = this.clean({
          first: 11,
          role_name: "partner",
          page: this.page,
          ordering: this.ordering,
          roles: this.roles,
          start_date: this.start_date,
          end_date: this.end_date,
          status: this.status,
          status_tab: this.status_tab,
        });
        localStorage.setItem("users_list." + this.me.id, JSON.stringify(variables));
        this.$store
          .dispatch("GET_USERS_PAGINATE", {
            context: this,
            variables: variables,
          })
          .then(() => {
            this.loading = false;
          });
      }
    },
    clean(obj) {
      for (let propName in obj) {
        if (
          Object.prototype.hasOwnProperty.call(obj, propName) &&
          (obj[propName] === null || obj[propName] === undefined)
        ) {
          delete obj[propName];
        }
      }
      return obj;
    },
    actionsReset() {
      this.page = 1;
      this.roles = null;
      this.start_date = null;
      this.end_date = null;
      this.status = null;
      this.getUsers();
    },
    changePage(e) {
      this.page = e;
      this.getUsers();
    },
    changeSort(event) {
      this.ordering = [];
      event.forEach((column) => {
        if (column.type.toUpperCase() !== "NONE") {
          this.ordering.push({
            orderColumn: column.field,
            orderBy: column.type.toUpperCase(),
          });
        }
      });
      this.getUsers();
    },
  },
  components: {
    LoaderIcon,
    LoadingIndicator,
    CloseIcon,
    CheckSymbolIcon,
    EditIcon,
    Table,
    ContractorsListActions,
  },
};
</script>

<style lang="stylus">
@import "~@/styles/parts/card.styl"
@import "~@/styles/parts/request.styl"
@import "~@/styles/mixins/svg.styl"
.contractor-btn {
  background var(--main_white)
  border 1px solid var(--main_light_gray)
  border-radius var(--button_radius)
  height 48px
  width 48px
  display inline-flex
  align-items center
  justify-content center

  .icon {
    width 18px
    height 18px
  }

  &--green {
    border-color var(--main_green_grass)
    background var(--main_green_grass)

    svg {
      svg(var(--main_white))
    }
  }

  &--red {
    border-color var(--error_red)

    svg {
      svg(var(--error_red))
    }
  }

  &--gray {
    border-color var(--main_light_gray)

    svg {
      svg(var(--main_light_gray))
    }
  }

  &__list {
    display inline-flex
    gap 12px
    align-items center
  }
}
</style>
