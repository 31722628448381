<template>
  <div
    class="filter-select"
    :class="{
      'filter-select--input': search.length || focused,
      'filter-select--filled': singleSelected || selectedOptions.length,
      'filter-select--list': opened,
      'filter-select--multiple': multiple,
      'filter-select--loading': loading,
      'filter-select--list-top': listTop,
    }"
    v-click-outside="blurList"
  >
    <input
      @focus="focus"
      @blur="blur"
      ref="input"
      class="filter-select__field"
      v-model="search"
      :placeholder="title"
      :size="inputWidth"
      v-show="!loading"
    />
    <span class="filter-select__empty" v-if="isEmpty" @click="focus" v-show="!loading">
      {{ singleSelected || emptyPlaceholder }}
    </span>
    <span
      v-show="!loading"
      class="filter-select__selected"
      :class="{
        'filter-select__selected--one': selectedOptions.length === 1,
      }"
      v-if="multiple && selectedOptions && selectedOptions.length"
    >
      <a href="#" @click.prevent="select(selectedOptions[0])" class="filter-select__selected-item">
        <span>{{ cutString(getName(selectedOptions[0]), 15) }}</span>
        <CloseIcon />
      </a>
      <a
        href="#"
        @click.prevent="focus"
        class="filter-select__selected-item"
        v-if="selectedOptions.length > 1"
      >
        <span>...</span>
      </a>
    </span>
    <button v-show="!loading" type="button" class="filter-select__action" tabindex="-1" @click="toggle">
      <ChevronDownIcon />
    </button>
    <FilterSelectListComponent
      v-show="!loading"
      ref="list"
      :options="filteredOptions"
      :selected="selectedOptions ? selectedOptions : value"
      v-if="opened"
      :multiple="multiple"
      :labelName="labelName"
      @select="select"
      @clean="clean"
    />
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";
import ChevronDownIcon from "../svg/ChevronDownIcon";
import FilterSelectListComponent from "./components/FilterSelectListComponent";
import CloseIcon from "../svg/CloseIcon";

export default {
  name: "FilterSelectComponent",
  props: {
    title: {
      type: String,
      required: true,
    },
    emptyPlaceholder: {
      type: String,
      default: "Все",
    },
    labelName: {
      type: String,
      default: "name",
    },
    options: Array,
    multiple: Boolean,
    loading: Boolean,
    value: [Number, String, Array, Object],
  },
  data() {
    return {
      singleSelected: null,
      selected: [],
      search: "",
      opened: false,
      listTop: false,
      scrollTimeout: undefined,
      focused: false,
      sbOptions: {
        minScrollbarLength: 20,
      },
      inputWidth: 100,
    };
  },
  computed: {
    isEmpty() {
      return !this.selectedOptions.length || !this.value;
    },
    selectedOptions() {
      if (this.value && this.value instanceof Array) {
        // Сделано так чтобы сохранять последовательность выбора
        return this.value
          .map((v) => {
            const option = this.options.find((o) => JSON.stringify(o) === JSON.stringify(v));
            if (option) {
              return option;
            }
            return false;
          })
          .filter((v) => v)
          .reverse();
        // return this.options.filter((o) => this.value.includes(o.id));
      }
      return false;
    },
    filteredOptions() {
      return this.options.filter((o) => this.getName(o).includes(this.search));
    },
  },
  watch: {
    opened() {
      if (this.opened) {
        this.$nextTick(() => {
          this.calculateListPosition();
        });
      }
    },
  },
  mounted() {
    // Если не несколько элементов, показываю в input
    if (this.value !== null && !this.multiple) {
      const option = this.options.find((o) => JSON.stringify(o) === JSON.stringify(this.value));
      if (option) {
        this.search = this.getName(option);
      }
    }
    window.addEventListener("scroll", () => {
      if (this.opened) {
        clearTimeout(this.scrollTimeout);
        this.scrollTimeout = setTimeout(() => {
          this.$nextTick(() => {
            this.calculateListPosition();
          });
        }, 200);
      }
    });
    this.calcInputWidth();
  },
  beforeDestroy() {
    window.removeEventListener("scroll", () => {});
  },
  methods: {
    calcInputWidth() {
      const input = this.$refs.input;
      let lettersCount = 0;
      if (input.value) {
        lettersCount = input.value.length;
      } else {
        lettersCount = input.placeholder.length;
      }
      this.inputWidth = lettersCount;
    },
    calculateListPosition() {
      if (this.$refs.list) {
        const height = this.$refs.list.$el.clientHeight;
        const top = this.$refs.list.$el.getBoundingClientRect().top;
        if (top + height > window.innerHeight) {
          this.listTop = true;
          return;
        }
        this.listTop = false;
      }
    },
    cutString(string, size) {
      return string.substr(0, size).trim() + (string.length > size ? ".." : "");
    },
    clean() {
      if (this.multiple) {
        this.$emit("input", []);
        return;
      }
      this.$emit("input", null);
      this.search = "";
    },
    /**
     * Обработка события выбора в списке
     */
    select(e) {
      // Если несколько элементов
      if (this.multiple) {
        // Открепляю от реактивного value
        let value = JSON.parse(JSON.stringify(this.value || [])).map((v) => JSON.stringify(v));
        // Если уже выбран - удаляю
        if (value.includes(JSON.stringify(e))) {
          value.splice(value.indexOf(JSON.stringify(e)), 1);
        } else {
          value.push(JSON.stringify(e));
        }
        this.$emit(
          "input",
          value.map((v) => (typeof v === "string" ? JSON.parse(v) : v))
        );
        this.$refs.input.focus();
        return;
      }
      // Если один элемент
      // this.search = this.getName(e);
      this.singleSelected = e ? this.getName(e) : null;
      this.$emit("input", e);
      this.opened = false;
    },
    /**
     * Фокус на input
     */
    focus() {
      this.$refs.input.focus();
      this.focused = true;
      this.opened = true;
    },
    /**
     * Расфокус на input
     */
    blur() {
      this.focused = false;
    },
    toggle() {
      this.focused = !this.focused;
      this.opened = !this.opened;
    },
    /**
     * Убрать список
     */
    blurList() {
      this.opened = false;
    },
    getName(option) {
      if (this.labelName) {
        return option[this.labelName];
      }
      return option;
    },
  },
  components: {
    CloseIcon,
    FilterSelectListComponent,
    ChevronDownIcon,
  },
  directives: {
    ClickOutside,
  },
};
</script>

<style lang="stylus">
@import "~@/styles/parts/input"
.filter-select {
  @extends .input
  transition background-color .3s
  height 40px
  background transparent
  border-color var(--main_light_divider);
  border-width 1px
  border-radius: var(--button_small_radius);
  min-width 170px;
  display flex
  align-items center
  padding-right 42px

  &--loading {
    background-color var(--main_light_divider);
    background: linear-gradient(90deg, var(--main_table_header) 0%, var(--main_accent-o12) 50%, var(--main_table_header) 100%);
    background-size: 400% 100%;
    animation: gradient 2s ease infinite;
  }

  &--filled {
    background var(--main_white)
  }

  &__empty {
    font-weight: 500;
    font-size: 0.8750em;
    line-height: 22px;
    letter-spacing: var(--letter_spacing_small);
    color: var(--main_dark);
    height 100%
    padding 9px 0
    display flex
    align-items center
    min-width 60px
  }

  &__action {
    padding 10px 12px
  }

  &--list {
    background var(--main_white)
    border-radius 4px 4px 0 0
    border-bottom-color transparent

    .filter-select__action {
      transform scaleY(-1)
      z-index 11
    }
  }

  &--list-top {
    border-radius 0 0 4px 4px

    .filter-select__list {
      border-radius 4px 4px 0 0
      absolute top left
      bottom initial
      transform translateY(calc(-100% + 2px))
    }
  }

  &__field {
    padding 9px 0 9px 12px
    font-size: 0.8750em;
    line-height: 20px;
    z-index 1
    color var(--main_dark)
    width auto
    height auto
  }

  &__selected {
    display grid
    grid-gap 4px
    grid-template-columns auto auto
    +below(480px) {
      display none
    }

    &::placeholder {
      color var(--main_default_dark)
    }

    &--one {
      grid-template-columns auto
    }

    .icon {
      width 12px
      height 12px
      display flex
      align-items center
      justify-content center
    }

    &-item {
      cursor pointer
      background var(--input_focused_border)
      border-radius 4px
      padding 2px 4px
      font-size: 0.8750em;
      line-height: 22px;
      text-align: center;
      letter-spacing: var(--letter_spacing_small);
      color: var(--main_black);
      text-decoration none

      &:hover {
        color var(--main_black)
      }

      &:first-child {
        display grid
        grid-template-columns auto 12px
        align-items center
      }
    }
  }

  &:not(.input-default)
  &:not(.filter-select--input) {
    border-color var(--main_light_divider) !important;

    .filter-select__title {
      font-size: 1em;
      line-height: 20px;
      width: 100%;
      height 100%
      padding 20px
    }
  }
}
</style>
