var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.blurList),expression:"blurList"}],staticClass:"filter-select",class:{
    'filter-select--input': _vm.search.length || _vm.focused,
    'filter-select--filled': _vm.singleSelected || _vm.selectedOptions.length,
    'filter-select--list': _vm.opened,
    'filter-select--multiple': _vm.multiple,
    'filter-select--loading': _vm.loading,
    'filter-select--list-top': _vm.listTop,
  }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"},{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],ref:"input",staticClass:"filter-select__field",attrs:{"placeholder":_vm.title,"size":_vm.inputWidth},domProps:{"value":(_vm.search)},on:{"focus":_vm.focus,"blur":_vm.blur,"input":function($event){if($event.target.composing){ return; }_vm.search=$event.target.value}}}),(_vm.isEmpty)?_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],staticClass:"filter-select__empty",on:{"click":_vm.focus}},[_vm._v(" "+_vm._s(_vm.singleSelected || _vm.emptyPlaceholder)+" ")]):_vm._e(),(_vm.multiple && _vm.selectedOptions && _vm.selectedOptions.length)?_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],staticClass:"filter-select__selected",class:{
      'filter-select__selected--one': _vm.selectedOptions.length === 1,
    }},[_c('a',{staticClass:"filter-select__selected-item",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.select(_vm.selectedOptions[0])}}},[_c('span',[_vm._v(_vm._s(_vm.cutString(_vm.getName(_vm.selectedOptions[0]), 15)))]),_c('CloseIcon')],1),(_vm.selectedOptions.length > 1)?_c('a',{staticClass:"filter-select__selected-item",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.focus.apply(null, arguments)}}},[_c('span',[_vm._v("...")])]):_vm._e()]):_vm._e(),_c('button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],staticClass:"filter-select__action",attrs:{"type":"button","tabindex":"-1"},on:{"click":_vm.toggle}},[_c('ChevronDownIcon')],1),(_vm.opened)?_c('FilterSelectListComponent',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],ref:"list",attrs:{"options":_vm.filteredOptions,"selected":_vm.selectedOptions ? _vm.selectedOptions : _vm.value,"multiple":_vm.multiple,"labelName":_vm.labelName},on:{"select":_vm.select,"clean":_vm.clean}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }