<template>
  <figure class="icon filter-icon">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22 3H2L10 12.46V19L14 21V12.46L22 3Z"
        stroke="#373F41"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></path>
    </svg>
  </figure>
</template>
<script>
export default {
  name: "FilterIcon",
};
</script>
