<template>
  <div class="modal-body" ref="modal">
    <button type="button" @click="$emit('close')" class="modal-body__close">
      <CloseIcon />
    </button>
    <h1 class="modal-body__header">Изменить</h1>
    <div class="modal-body__form">
      <InputComponent
        title="ФИО"
        type="text"
        :error="$v.form.fio.value.$error"
        v-model.trim.lazy="$v.form.fio.value.$model"
      />
      <InputComponent
        title="Телефон"
        type="text"
        :error="$v.form.phone.value.$error"
        v-model.trim.lazy="$v.form.phone.value.$model"
        mask="# (###) ###-##-##"
      />
      <InputComponent
        title="Электронная почта"
        type="email"
        :error="$v.form.email.value.$error"
        v-model.trim.lazy="$v.form.email.value.$model"
      />
      <SelectComponent
        v-if="roles.length"
        title="Выберите роли"
        labelName="name_ru"
        :loading="!isCreated"
        v-model="$v.form.role_ids.value.$model"
        :error="$v.form.role_ids.value.$error"
        :options="roles"
        multiple
      />
      <SelectComponent
        title="Выберите ваши компетенции"
        labelName="name_ru"
        :loading="!isCreated"
        v-if="!isEmployeeRoleSelected && permissions.length"
        v-model="$v.form.permission_ids.value.$model"
        :error="$v.form.permission_ids.value.$error"
        :options="permissions"
        multiple
      />
      <SelectComponent
        title="Выберите куратора"
        labelName="title"
        v-if="users.length && isCreated"
        v-model="$v.form.curator_id.value.$model"
        :error="$v.form.curator_id.value.$error"
        :options="users"
      />
      <div class="modal-body__controls">
        <button
          type="button"
          class="back-btn modal-body__controls--no-mobile"
          @click="$emit('close')"
          :disabled="loading"
        >
          Отменить
        </button>
        <button
          :class="{
            'btn--loading': loading,
          }"
          class="btn"
          type="button"
          @click="submit"
        >
          <template v-if="!loading">Отправить</template>
        </button>
        <button
          type="button"
          class="btn btn--outline modal-body__controls--mobile"
          @click="$emit('close')"
          :disabled="loading"
        >
          Закрыть
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import CloseIcon from "@/components/svg/CloseIcon";
import InputComponent from "@/components/inputs/InputComponent";
import SelectComponent from "@/components/inputs/SelectComponent";
import { required, email, minLength, requiredIf } from "vuelidate/lib/validators";

export default {
  name: "EditUserModal",
  props: {
    options: Object,
  },
  data() {
    return {
      loading: false,
      isCreated: false,
      form: {
        email: {
          message: null,
          value: null,
        },
        phone: {
          message: null,
          value: null,
        },
        fio: {
          message: null,
          value: null,
        },
        role_ids: {
          message: null,
          value: null,
        },
        permission_ids: {
          message: null,
          value: null,
        },
        curator_id: {
          message: null,
          value: null,
        },
      },
    };
  },
  validations: {
    form: {
      fio: {
        value: {
          required,
          isFIO: (value) => {
            if (value && typeof value.split === "function") {
              return value.split(" ").length >= 3;
            }
            return false;
          },
        },
      },
      phone: {
        value: {
          required,
          minLength: minLength(17),
        },
      },
      email: {
        value: {
          required,
          email,
          minLength: minLength(5),
        },
      },
      role_ids: {
        value: {
          required,
        },
      },
      permission_ids: {
        value: {
          required: requiredIf(function () {
            return !this.isEmployeeRoleSelected;
          }),
        },
      },
      curator_id: {
        value: {},
      },
    },
  },
  created() {
    this.$store
      .dispatch("GET_PARTNER_EDIT_PAGE", {
        context: this,
      })
      .then(() => {
        this.form.phone.value = this.options.user.phone;
        this.form.email.value = this.options.user.email;
        this.form.fio.value = `${this.options.user.surname} ${this.options.user.name} ${this.options.user.patronymic}`;
        // Куратор
        let curator_id = this.options.user.supplier;
        if (curator_id) {
          this.form.curator_id.value = this.users.find((u) => u.id === curator_id.id);
        }
        // Роли
        this.form.role_ids.value = this.roles.filter((r) => {
          return r.type.code !== 3 && this.options.user.role.map((s) => s.id).includes(r.id);
        });
        // Компетенции
        this.form.permission_ids.value = this.options.user.role.filter((item) => {
          return item.type.code === 3;
        });
        this.isCreated = true;
      });
  },
  watch: {
    isEmployeeRoleSelected(selected) {
      if (selected) {
        this.form.permission_ids.value = null;
      }
    },
  },
  computed: {
    reasons() {
      return this.$store.state.reasons;
    },
    isEmployeeRoleSelected() {
      if (this.form.role_ids.value) {
        return (
          this.form.role_ids.value.filter((role) => {
            return role.parent && role.parent.name === "employee";
          }).length > 0
        );
      }
      return false;
    },
    roles() {
      return this.$store.state.roles;
    },
    permissions() {
      return this.$store.state.permissions;
    },
    users() {
      return this.$store.state.users
        .filter((u) => {
          return u.role.filter((r) => {
            return r.name === "supplier";
          }).length;
        })
        .map((u) => {
          u.title = `${u.surname} ${u.name} ${u.patronymic}`;
          return u;
        });
    },
  },
  methods: {
    roleSelectChange(event) {
      this.form.role_ids.value = event;
    },
    formatData(data, key) {
      if (this.form[key].value) {
        if (key === "fio") {
          const fio = this.form[key].value.split(" ");
          if (fio.length >= 3) {
            data["surname"] = fio[0];
            data["name"] = fio[1];
            data["patronymic"] = fio.slice(2, fio.length).join(" ");
          }
        } else if (key === "permission_ids") {
          data.competence_ids = [];
          data.competence_ids.push(...this.form[key].value.map((p) => p.id));
        } else if (typeof this.form[key].value === "object") {
          if (this.form[key].value instanceof Array) {
            data[key] = this.form[key].value.map((v) => v.id);
          } else {
            data[key] = this.form[key].value.id;
          }
        } else {
          data[key] = this.form[key].value;
        }
      }
      return data;
    },
    isValid() {
      this.$v.$touch();
      return !this.$v.$invalid;
    },
    submit() {
      if (!this.loading) {
        this.loading = true;
        let data = {
          id: this.options.user.id,
        };
        if (this.isValid()) {
          Object.keys(this.form).forEach((key) => {
            data = this.formatData(data, key);
          });
        }
        this.$store
          .dispatch("EDIT_USER_BY_ID", {
            context: this,
            variables: data,
          })
          .then(() => {
            this.options.callback();
            this.loading = false;
            this.$emit("close");
          });
      }
    },
  },
  components: { InputComponent, CloseIcon, SelectComponent },
};
</script>
